::-webkit-scrollbar {
  display: none;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-80%);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}  
  .open-sans-regular {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }
  
  .ubuntu-regular {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .ubuntu-medium {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .ubuntu-bold {
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  
 
.animate-marquee {
  animation: marquee 10s linear infinite;
}

.animate-marquee2 {
  animation: marquee 10s linear infinite;
}




